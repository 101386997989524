import React from "react";
import StartProjectCard from "./AllCards/StartProjectCard";
import { Link } from "react-router-dom";

export default function OurWork() {
  return (
    <>
      <div className="service_header">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <h1>Our Work</h1>
            </div>
            <div className="col-auto graphIcon">
              <img src="./images/graphic_1.svg" alt="graphic" />
            </div>
          </div>
        </div>
      </div>
      <section className="all_services">
        <div className="container">
          <div className="work_list">
            {/* {data?.map((ele) => {
              return (
                <div className="s_items">
                  <h2>{ele?.title}</h2>
                  <p>{ele?.des}</p>
                  <figure>
                    <img src={ele.icon} alt="" />
                  </figure>
                  <Link to={ele.pathname} className="stretched-link"></Link>
                </div>
              );
            })} */}
            <div className="work_items">
              <div className="img-container">
                <img src="/images/work-img/iirf.jpg" alt="" />
              </div>
              <div className="project-details mt-4">
                <h2>IIRF Centre For Institutional Research</h2>
                <p>
                  Indian Institutional Ranking Framework (IIRF) ranks more than
                  1,000 institutions (300+ Universities, 350 Engineering
                  colleges, 150+ B-Schools, 50 Law colleges, 50 Design schools,
                  50 Architecture colleges and 100+ Undergraduate colleges for
                  BBA & BCA) across the country.
                </p>
                <p>
                  <b>Technologies Used:</b>{" "}
                  <span className="tech-stack">
                    Node.js, Angular, WordPress, MongoDB, Express.js
                  </span>
                </p>
                {/* <Link to="https://iirfranking.com/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img src="/images/work-img/ep.jpg" alt=" education post" />
              </div>
              <div className="project-details mt-4">
                <h2>Education Post</h2>
                <p>
                  Education Post News (EPN) emerges as a dynamic online news
                  portal platform, expanding the horizons of information
                  dissemination beyond conventional boundaries. Established
                  under the auspices of SAT SUKRIT MEDIA PVT. LTD., EPN inherits
                  the rich legacy of its predecessor, The Education Post
                  magazine, which has been a pioneering force in India's higher
                  education landscape since its inception in 2012.
                </p>
                <p>
                  <b>Technologies Used:</b>{" "}
                  <span className="tech-stack">
                    Node.js, React.js Next.js, MongoDB, Express.js, AWS
                  </span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>

            {/* <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/iirf-study-abroad.png"
                  alt="study abroad"
                />
              </div>
              <div className="project-details mt-4">
                <h2>IIRF Study Abroad</h2>
                <p>
                  Indian Institutional Ranking Framework (IIRF) ranks more than
                  1,000 institutions (300+ Universities, 350 Engineering
                  colleges, 150+ B-Schools, 50 Law colleges, 50 Design schools,
                  50 Architecture colleges and 100+ Undergraduate colleges for
                  BBA & BCA) across the country.
                </p>
                <p>
                  <b>Technologies Used:</b>{" "}
                  <span className="tech-stack">
                    Node.js, React, Bootstrap, MongoDB, Express.js, AWS.
                  </span>
                </p>
              </div>
            </div> */}
            <div className="work_items">
              <div className="img-container">
                <img src="/images/work-img/ugpg.jpg" alt="ug pg global" />
              </div>
              <div className="project-details mt-4">
                <h2>UG PG Global</h2>
                <p>
                  UGPG Global facilitates regular interactions with industry
                  leaders, offering students firsthand insights into current
                  market trends and business practices. These engagements not
                  only enhance learning but also provide valuable networking
                  opportunities, bridging the gap between theoretical knowledge
                  and practical application
                </p>
                <p>
                  <b>Technologies Used:</b>{" "}
                  <span className="tech-stack">WordPress, MongoDB</span>
                </p>
                {/* <Link to="https://ugpgglobal.com/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img src="/images/work-img/fwa.jpg" alt="fwa" />
              </div>
              <div className="project-details mt-4">
                <h2>Federation For World Academy (FWA)</h2>
                <p>
                  Federation for World Academics (FWA) is a unique concept to
                  bring all academicians world over to share their knowledge and
                  experience for their personal excellence and also to
                  contribute benefits to humanity at large. It is a non profit,
                  non political registered body of Section -8 of the Companies
                  Act 2013 and Rules 18 the Companies (Incorporation) Rules by
                  the Ministry of Corporate Affairs, Government of India.
                </p>
                <p>
                  <b>Technologies Used:</b>{" "}
                  <span className="tech-stack">WordPress, MongoDb</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img src="/images/work-img/imu.jpg" alt="imu university" />
              </div>
              <div className="project-details mt-4">
                <h2>IMU UNIVERSITY</h2>
                <p>
                  IMU University (formerly known as International Medical
                  University), where possibilities are limitless. As Malaysia’s
                  first private institution for medical and health sciences
                  education, we have evolved to bridge science with business,
                  technology, and social sciences, shaping future leaders with
                  diverse perspectives.
                </p>
                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">
                    Seo, Smo, Digital Marketing
                  </span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/celebrityink.jpg"
                  alt="celebrity ink"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Celebrity Ink™</h2>
                <p>
                  Celebrity Ink™ - Asia-Pacific’s Leading Tattoo Brand, the
                  world’s first experiential tattoo and body art studio,
                  creating a space for self expression and individualism.
                </p>

                <p>
                  <b>Services Provided:</b>
                  <span className="tech-stack">
                    Seo, Smo, Digital marketing
                  </span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>

            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/ep-admin.png"
                  alt="admin dashboard"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Admin Dashboard</h2>
                <p>
                  Education Post Admin Dashboard is a robust and feature-rich
                  tool meticulously crafted with React, Redux, Node.js, MongoDB,
                  Bootstrap, and Tailwind CSS. This integrated platform empowers
                  administrators by centralizing content management and user
                  administration for the Education Post news application.
                </p>
                <p>
                  <b>Technologies Used:</b>{" "}
                  <span className="tech-stack">
                    Node.js, React, Bootstrap, MongoDB, Express.js, AWS.
                  </span>
                </p>
              </div>
            </div>

            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/quickembassy.png"
                  alt="quick embassy"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Quick Embassy</h2>
                <p>
                  A team of young, technically-minded professionals run the show
                  at the Quick Embassy Attestation, which is a
                  government-registered company. Quick Attestation Services is a
                  firm that does exactly what its name implies: it attests
                  certificates in a timely and trustworthy manner. Different
                  embassies may be able to provide you with these services. In
                  regards to attestation needs, go no further than Quick
                  Attestation, your one-stop solution service center.
                </p>
                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">Seo, Smo</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img src="/images/work-img/beta-soft.png" alt="beta soft" />
              </div>
              <div className="project-details mt-4">
                <h2>Beta Soft Technology</h2>
                <p>
                  Beta Soft Technology is an avant-garde company having
                  specialization in website designing, website development,
                  mobile technologies and business promotion or SEO. Our
                  technology driven company is ahead in terms of adapting the
                  latest technical trends and implementing them efficiently.
                  Beta Soft Technology is run by experienced and highly skilled
                  technocrats, who bring their valuable efforts from various
                  verticals and domains. We are the wisdom beacon to every
                  member of the team. They empower and motivate to bring
                  innovation.
                </p>
                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">Seo, Smo</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/kapoor.png"
                  alt="kapoor study circle"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Kapoor Study Circle</h2>
                <p>
                  Kapoor Study Circle is an avant-garde institution specializing
                  in comprehensive education and student development. Our
                  technology-driven approach ensures that we stay ahead in terms
                  of adapting the latest educational trends and implementing
                  them efficiently. Established with the vision of empowering
                  students, Kapoor Study Circle offers a range of courses
                  designed to enhance knowledge and skills. Our institution is
                  run by experienced and highly skilled educators who bring
                  their valuable expertise from various educational domains.
                  They empower and motivate students to bring innovation and
                  achieve academic excellence. The college opens its doors every
                  year in September, welcoming new students to join our
                  community of learners.
                </p>

                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">Seo, Smo</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/arihant.png"
                  alt="arihant helicopter"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Arihant Helecopter Service</h2>
                <p>
                  Arihant Helecopter Service (INDIA) gets you to your
                  destination in the most exclusive and secure way possible.
                  With our exclusive aircraft & Helicopter charter flights, you
                  have the ability to save time and money, eliminating the
                  hassle of long check-ins ques, aircraft changes and night
                  halts common with conventional airline services.
                </p>

                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">Seo, Smo</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/ikelectronic.png"
                  alt="ikelectronic image"
                />
              </div>
              <div className="project-details mt-4">
                <h2>IK Electronic</h2>
                <p>
                  IK Electronics website has successfully positioned the company
                  as a leader in the technology solutions space. The modern
                  design and enhanced user experience have led to increased user
                  engagement and higher conversion rates. The comprehensive
                  display of services and products has attracted new clients,
                  while the emphasis on quality and dedicated service has
                  strengthened existing client relationships.
                </p>

                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">Seo, Smo</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/international.png"
                  alt="iternational trade promotion image"
                />
              </div>
              <div className="project-details mt-4">
                <h2>International Trade Promotion Centre</h2>
                <p>
                  ITPC is a bright star in the business galaxy which has been
                  dedicated in thoroughly promoting the internal as well as
                  international trade by imparting intensive education to the
                  incumbents. We groom and develop their personalities, enhance
                  their skills so that they can get ready to face the challenges
                  of the future and stand firmly among the vast competition of
                  the corporate world. We help the economy to flourish by also
                  promoting the international as well as Indian trade by
                  imparting intensive training and education to our candidates.
                  A youth in search of identity and future should naturally
                  prefer shelters under a tree that's trunk & leafs are thick.
                  We act as a catalyst in tailor making these youth into the
                  corporate leaders of tomorrow.
                </p>

                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">Seo, Smo</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/mdc.png"
                  alt="marwaha dental clinic"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Marwaha Dental Clinic</h2>
                <p>
                  Marwaha Dental Clinic is a Multi speciality Dental clinic in
                  DLF Phase 2 providing world class dental treatment. It is
                  operating since 2004, providing all specialties of dentistry
                  under one roof. The dental clinic in DLF Phase-2 aims at
                  providing best dental treatment to meet all demands of
                  patients. At Marwaha Dental Clinic (dentist in DLF Phase-2)
                  providing services at economical prices, by combining years of
                  professional experience together with the latest technology.
                </p>

                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">Seo, Smo</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/unistar.png"
                  alt="unistar aqutech image"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Unistar Aqutech Private Limited</h2>
                <p>
                  "Unistar Aqutech Private Limited" have been relentless in our
                  efforts and we have managed to seek an NSIC, MSME, ISO
                  9001:2008 certification owing to our regarded services. For
                  ease of access and for service, we have set up our corporate
                  office at New Delhi while our manufacturing plant is located
                  at Ghaziabad, Uttar Pradesh, India. We are a leading
                  manufacturer and supplier of high-quality water and waste
                  treatment machinery.
                </p>

                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">Seo, Smo</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/birthday.png"
                  alt="birthday organizer"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Birthday Organizer</h2>
                <p>
                  The Birthday Organizer website has revolutionized the way
                  people plan and organize birthday parties. The engaging design
                  and user-centric features have significantly enhanced user
                  satisfaction and increased the number of bookings. The diverse
                  range of themes and packages has attracted a broad clientele,
                  while the interactive planner has made the process of
                  organizing a birthday party enjoyable and stress-free.
                </p>

                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">Seo, Smo</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img src="/images/work-img/detective.png" alt="sleuths india" />
              </div>
              <div className="project-details mt-4">
                <h2>Sleuths India</h2>
                <p>
                  Trust and hire an investigation firm with 25 years of market
                  experience in solving complex and difficult cases. You can be
                  confident that your case will produce the desired results.
                  Believe in a team of professionals with a proven track record
                  of solving hundreds of cases. In addition, a top Private
                  Detective in Hyderabad guarantees confidentiality and peace of
                  mind!
                </p>

                <p>
                  <b>Services Provided:</b>{" "}
                  <span className="tech-stack">Seo, Smo</span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>

            <div className="work_items">
              <div className="img-container">
                <img src="/images/work-img/essaar.png" alt="essaar motors" />
              </div>
              <div className="project-details mt-4">
                <h2> Ess Aar Motors</h2>
                <p>
                  Ess Aar Motors are New Delhi's first authorized dealers of
                  Royal Enfield motorcycles, established since 1978. With retail
                  business since more than 40 years, Ess Aar Motors is well
                  positioned to satisfy customer demands in Sales & Service.
                  World-class facilities enable a competitive team to get ahead
                  of others in customer enhancement.
                </p>

                <p>
                  <b>Services Provided:</b>
                  <span className="tech-stack">
                    Seo, Smo, Digital marketing
                  </span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/nbs.png"
                  alt="narayana bussiness school"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Narayana Business School </h2>
                <p>
                  Narayana Business School Strives With Determination To Be
                  Among The Finest, Making The Most Of Every Opportunity To
                  Improve Higher Education, Training, And Research And
                  Development. We Believe In Nurturing Not Just The Intellect
                  But The Character, Creativity, And Resilience Of Each Student
                  Making Them Industry Ready.
                </p>

                <p>
                  <b>Services Provided:</b>
                  <span className="tech-stack">
                    Seo, Smo, Digital marketing
                  </span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/dsu.png"
                  alt="Dayananda Sagar University"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Dayananda Sagar University</h2>
                <p>
                  Dayananda Sagar University is one of the best university in
                  Bangalore, Karnataka. Offering various professional courses in
                  Engineering, MBA, Law, Ph.D,etc.
                </p>

                <p>
                  <b>Services Provided:</b>
                  <span className="tech-stack">
                    Seo, Smo, Digital marketing
                  </span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/taxila.png"
                  alt="Taxila Business School"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Taxila Business School</h2>
                <p>
                  Taxila Business School To be a nationally and internationally
                  recognized academic institution known for academic excellence,
                  continuous innovation and creating donors for the society.
                </p>

                <p>
                  <b>Services Provided:</b>
                  <span className="tech-stack">
                    Seo, Smo, Digital marketing
                  </span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            {/* <div className="work_items">
              <div className="img-container">
                <img src="/images/work-img/vtt.png" alt="" />
              </div>
              <div className="project-details mt-4">
                <h2>VTT University</h2>
                <p>
                  VTT University offers a diverse array of programs spanning
                  multiple disciplines, including engineering, medicine,
                  business, arts, and sciences. These programs are meticulously
                  crafted to cater to the ever-evolving demands of industries,
                  equipping students with the knowledge, skills, and practical
                  experience essential for thriving in their future careers.
                </p>

                <p>
                  <b>Services Provided:</b>
                  <span className="tech-stack">
                    Seo, Smo, Digital marketing
                  </span>
                </p>
              </div>
            </div> */}
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/vtt.png"
                  alt="VTT University, Vietnam"
                />
              </div>
              <div className="project-details mt-4">
                <h2>VTT University, Vietnam</h2>
                <p>
                  VTT University offers a diverse array of programs spanning
                  multiple disciplines, including engineering, medicine,
                  business, arts, and sciences. These programs are meticulously
                  crafted to cater to the ever-evolving demands of industries,
                  equipping students with the knowledge, skills, and practical
                  experience essential for thriving in their future careers.
                </p>

                <p>
                  <b>Services Provided:</b>
                  <span className="tech-stack">
                    Seo, Smo, Digital marketing
                  </span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img
                  src="/images/work-img/surya.png"
                  alt="Suryadatta Group of Institutes"
                />
              </div>
              <div className="project-details mt-4">
                <h2>Suryadatta Group of Institutes</h2>
                <p>
                  Suryadatta Group of Institutes, trusted name for quality
                  Education for holisitic development for assured success in the
                  schools, colleges including MBA/PGDM, Ph.D, other cource
                  certificates and diplomas with placement support.
                </p>

                <p>
                  <b>Services Provided:</b>
                  <span className="tech-stack">
                    Seo, Smo, Digital marketing
                  </span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
            <div className="work_items">
              <div className="img-container">
                <img src="/images/work-img/admas.png" alt="Adamas University" />
              </div>
              <div className="project-details mt-4">
                <h2>Adamas University</h2>
                <p>
                  Adamas University, rated the Best Private University in
                  Kolkata, West Bengal, India, was established to provide a
                  solution to all educational needs under one roof. It aims to
                  create an environment for students that will not just arm them
                  with the right kind of knowledge, but also develop them as
                  well-rounded members of society.
                </p>

                <p>
                  <b>Services Provided:</b>
                  <span className="tech-stack">
                    Seo, Smo, Digital marketing
                  </span>
                </p>
                {/* <Link to="https://educationpost.in/">Link to Project</Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <StartProjectCard />
    </>
  );
}
