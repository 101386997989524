import React from "react";
import { Element } from "react-scroll";
// import '../../../public/images/menu-icon/fluter.svg'
export const technology = [
  {
    id: 1,
    techArr: [
      { src: "/images/menu-icon/flutter.svg", name: "Flutter" },
      { src: "/images/menu-icon/react.svg", name: "React Native" },
      { src: "/images/menu-icon/iconic.svg", name: "Ionic" },
      { src: "/images/menu-icon/java.svg", name: "Java" },
      { src: "/images/menu-icon/swift.svg", name: "Swift" },
      { src: "/images/menu-icon/xman.svg", name: "Xamarin" },
      { src: "/images/menu-icon/cordova.svg", name: "Apache Cordova" },
      { src: "/images/menu-icon/native.svg", name: "Native Script" },
      { src: "/images/menu-icon/jquery.svg", name: "jQuery Mobile" },
      { src: "/images/menu-icon/framework.svg", name: "Framework7" },
      {
        src: "/images/menu-icon/kotlin.svg",
        name: "Kotlin Multiplatform Mobile",
      },
    ], // Using the existing mobile array directly
    heading: "Mobile App",
    desc: "Create cutting-edge mobile applications that deliver seamless user experiences across platforms.",
  },
  {
    id: 2,
    techArr: [
      { src: "/images/menu-icon/react.svg", name: "React" },
      { src: "/images/menu-icon/angular.svg", name: "Angular" },
      { src: "/images/menu-icon/vue.svg", name: "Vue.js" },
      { src: "/images/menu-icon/node.svg", name: "Node.js" },
      { src: "/images/menu-icon/django.svg", name: "Django" },
      { src: "/images/menu-icon/rails.svg", name: "Ruby on Rails" },
    ],
    heading: "Web App",
    desc: "Build robust web applications tailored to meet business objectives, ensuring scalability and performance.",
  },
  {
    id: 3,
    techArr: [
      { src: "/images/menu-icon/shopify.svg", name: "Shopify" },
      { src: "/images/menu-icon/magento.svg", name: "Magento" },
      { src: "/images/menu-icon/woocommerce.svg", name: "WooCommerce" },
      { src: "/images/menu-icon/bigcommerce.svg", name: "BigCommerce" },
    ],
    heading: "Ecommerce",
    desc: "Develop scalable ecommerce solutions that drive online sales, enhance customer experience, and optimize business operations.",
  },
  {
    id: 4,
    techArr: [
      { src: "/images/menu-icon/figma.svg", name: "Figma" },
      { src: "/images/menu-icon/adobe-xd.svg", name: "Adobe XD" },
      { src: "/images/menu-icon/invision.svg", name: "InVision" },
    ],
    heading: "UI/UX",
    desc: "Design intuitive user interfaces and compelling user experiences that engage and delight users.",
  },
  {
    id: 5,
    techArr: [
      { src: "/images/menu-icon/unity.svg", name: "Unity" },
      { src: "/images/menu-icon/unrealengine.svg", name: "Unreal Engine" },
      { src: "/images/menu-icon/light-gamemaker.svg", name: "GameMaker Studio" },
    ],
    heading: "Game",
    desc: "Design and develop immersive gaming experiences with captivating gameplay and stunning visuals.",
  },
  {
    id: 6,
    techArr: [
      { src: "/images/menu-icon/docker.svg", name: "Docker" },
      { src: "/images/menu-icon/kubernetes.svg", name: "Kubernetes" },
      { src: "/images/menu-icon/jenkins.svg", name: "Jenkins" },
      { src: "/images/menu-icon/ansible.svg", name: "Ansible" },
      { src: "/images/menu-icon/terraform.svg", name: "Terraform" },
    ],
    heading: "Devops",
    desc: "Implement efficient DevOps practices to streamline development, enhance collaboration, and automate software delivery pipelines.",
  },
  {
    id: 7,
    techArr: [
      { src: "/images/menu-icon/aws.svg", name: "AWS" },
      { src: "/images/menu-icon/azure.svg", name: "Azure" },
      { src: "/images/menu-icon/google.svg", name: "Google Cloud Platform" },
    ],
    heading: "Cloud",
    desc: "Utilize cloud computing technologies to build scalable and resilient infrastructure solutions, enabling agile business operations and growth.",
  },
];
function Technologies() {
  return (
    <div className="technology-main-page-container">
      {technology.map((item, i) => (
        <Element name={String(item.id)} key={i}>
          <section className="our_client">
            <div className="container">
              <div className="team_title text-center mb-lg-5 mb-3">
                <h3>{item.heading}</h3>
                <p>{item.desc}</p>
              </div>
              <div className="cl_main">
                {item.techArr.map((ele, index) => (
                  <div className="tech-img-container" key={index}>
                    {/* <img src="/images/menu-icon/fluter.svg" alt={ele.name} /> */}
                    <img src={ele.src} alt={ele.name} />
                    <p>{ele.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </Element>
      ))}
    </div>
  );
}

export default Technologies;

// import React from "react";

// function Technologies() {
//   const client = [
//     { id: 1, src: "./images/cl_logo.svg" },
//     { id: 2, src: "./images/cl_logo-1.svg" },
//     { id: 3, src: "./images/cl_logo-2.svg" },
//     { id: 4, src: "./images/cl_logo-3.svg" },
//     { id: 5, src: "./images/cl_logo-4.svg" },
//     { id: 6, src: "./images/cl_logo-5.svg" },
//     { id: 7, src: "./images/cl_logo-6.svg" },
//     { id: 8, src: "./images/cl_logo-7.svg" },
//   ];
//   const technology = [
//     {
//       id: 1,

//       heading: "Mobile App",
//       desc: "Create cutting-edge mobile applications that deliver seamless user experiences across platforms.",
//     },
//     {
//       id: 2,
//       heading: "Web App",
//       desc: "Build robust web applications tailored to meet business objectives, ensuring scalability and performance.",
//     },
//     {
//       id: 3,
//       heading: "Ecommerce",
//       desc: "Develop scalable ecommerce solutions that drive online sales, enhance customer experience, and optimize business operations.",
//     },
//     {
//       id: 4,
//       heading: "UI/UX",
//       desc: "Design intuitive user interfaces and compelling user experiences that engage and delight users.",
//     },
//     {
//       id: 5,
//       heading: "Game",
//       desc: "Design and develop immersive gaming experiences with captivating gameplay and stunning visuals.",
//     },
//     {
//       id: 6,
//       heading: "Devops",
//       desc: "Implement efficient DevOps practices to streamline development, enhance collaboration, and automate software delivery pipelines.",
//     },
//     {
//       id: 7,
//       heading: "Cloud",
//       desc: "Utilize cloud computing technologies to build scalable and resilient infrastructure solutions, enabling agile business operations and growth.",
//     },
//   ];

//   return (
//     <div className="technology-main-page-container">
//       {technology.map((item, i) => (
//         <section className="our_client " key={i}>
//           <div className="container">
//             <div className="team_title text-center mb-lg-5 mb-3">
//               <h3>{item.heading}</h3>
//               <p>{item.desc}</p>
//             </div>
//             <div className="cl_main">
//               {client?.map((ele) => {
//                 return (
//                   <div className="cl_logo">
//                     <img src={ele?.src} alt="" />
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         </section>
//       ))}
//     </div>
//   );
// }

// export default Technologies;
