import React from "react";
import {
  BsFillTelephoneFill,
  BsGeoAltFill,
  BsEnvelopeAtFill,
  BsChevronRight,
  BsLinkedin,
  BsTwitter,
  BsFacebook,
  BsInstagram,
} from "react-icons/bs";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="ft_logo">
          <img src="./images/logo.svg" alt="pyndertech logo" />
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 company">
            <h5>Company</h5>
            <p>
              Software Product Engineering Development Outsourcing Robotic
              Process Automation Mobile App Development Quark & Adobe PlugIn
              Development
            </p>
            <ul className="social_media">
              <li>
                <Link to="">
                  <BsInstagram />
                </Link>
              </li>
              <li>
                <Link to="https://www.facebook.com/pyndertech/">
                  <BsFacebook />
                </Link>
              </li>
              <li>
                <Link to="">
                  <BsTwitter />
                </Link>
              </li>
              <li>
                <Link to="https://www.linkedin.com/company/102766446/admin/feed/posts/">
                  <BsLinkedin />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3">
            <h5>Company</h5>
            <ul className="ft_menu">
              <li>
                <Link to="/about">
                  <BsChevronRight /> About Us
                </Link>
              </li>
              <li>
                <Link to="/company">
                  <BsChevronRight /> Company
                </Link>
              </li>
              {/* <li>
                <Link to="/career">
                  <BsChevronRight /> Careers
                </Link>
              </li> */}
              <li>
                <Link to="/blog">
                  <BsChevronRight /> Blogs
                </Link>
              </li>
              {/* <li>
                <Link to="/privacy">
                  <BsChevronRight /> Privacy
                </Link>
              </li> */}
              <li>
                <Link to="/contactus">
                  <BsChevronRight /> Contact Us
                </Link>
              </li>
              <li>
                <Link to="/services">
                  <BsChevronRight /> Services
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3">
            <h5>Services</h5>
            <ul className="ft_menu">
              <li>
                <Link to="/website-development">
                  <BsChevronRight /> Website Development
                </Link>
              </li>
              <li>
                <Link to="/mobile-development">
                  <BsChevronRight /> Mobile App development
                </Link>
              </li>
              <li>
                <Link to="/software-development">
                  <BsChevronRight /> Software Development
                </Link>
              </li>
              <li>
                <Link to="/software-security">
                  <BsChevronRight /> Software Security
                </Link>
              </li>
              <li>
                <Link to="/ui-ux-design">
                  <BsChevronRight /> UI/UX
                </Link>
              </li>
              <li>
                <Link to="/crm-system">
                  <BsChevronRight /> CRM System
                </Link>
              </li>
              <li>
                <Link to="/web-hosting">
                  <BsChevronRight /> Web Hosting
                </Link>
              </li>
              <li>
                <Link to="/digital-marketing">
                  <BsChevronRight /> Digital Marketing
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3">
            <h5>Get in touch with us</h5>
            <ul className="address">
              <li>
                <div>
                  <BsEnvelopeAtFill />
                </div>
                <Link to="mailto:enquiry@pyndertech.com">
                  enquiry@pyndertech.com
                </Link>
              </li>
              <li>
                <div>
                  <BsFillTelephoneFill />
                </div>{" "}
                {/* 8800306519 */}
                <Link to="tel:+918800306519">9289926105</Link>
              </li>
              <li>
                <div>
                  <BsGeoAltFill />
                </div>{" "}
                B-204, Second Floor, Above SBI Bank, Ansal Chambers-1, Bhikaji
                Cama Place, New Delhi, Delhi 110066
              </li>
            </ul>
          </div>
        </div>
        <div className="row  copyright">
          <div className="col-md-6">
            <p >
              © 2021 <span>Pyndertech</span>. All Rights Reserved
            </p>
          </div>
          {/* <div className="col-md-6">
            <ul className="blankSpace">
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
