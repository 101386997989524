import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { FaNodeJs, FaAws, FaFigma } from "react-icons/fa6";
import { RiReactjsLine, RiNextjsFill } from "react-icons/ri";
import { BiLogoMongodb } from "react-icons/bi";
import { FaJava } from "react-icons/fa6";
import { BiLogoFlutter } from "react-icons/bi";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import img1 from "../../clientimages/img1.png";
import img2 from "../../clientimages/img2.png";
import img3 from "../../clientimages/img3.png";
import img4 from "../../clientimages/img4.png";
import img5 from "../../clientimages/img5.png";
import img6 from "../../clientimages/img6.png";
import img7 from "../../clientimages/img7.png";
import img8 from "../../clientimages/img8.png";

function Home() {
  const [active, setActive] = useState(1);
  const [activeData, setActiveData] = useState({
    id: 1,
    title: "E-Commerce",
    src: "./images/uncheck.png",
    icon: "./images/ecomIcon/magento.svg",
    iconTitle: "Magento",
    activeimg: "./images/check.png",
    text: "Saving time By simply pressing a button, you can automate routine tasks and let the system take care of them. Never again will you have to waste time performing tasks by hand! Boost Revenues With tailored solutions that draw more customers, save them time, and foster brand loyalty, you can make more money. Keep your security standards high. You can guarantee that the data and payments of your clients are always safe and secure by utilizing cutting-edge technology, such as blockchain encryption.",
    techArr: [
      { src: "/images/menu-icon/shopify.svg", name: "Shopify" },
      { src: "/images/menu-icon/magento.svg", name: "Magento" },
      { src: "/images/menu-icon/woocommerce.svg", name: "WooCommerce" },
      { src: "/images/menu-icon/bigcommerce.svg", name: "BigCommerce" },
    ],
  });
  const data = [
    {
      id: 1,
      title: "E-Commerce",
      src: "./images/uncheck.png",
      icon: "./images/ecomIcon/magento.svg",
      iconTitle: "Magento",
      activeimg: "./images/check.png",
      text: "Saving time By simply pressing a button, you can automate routine tasks and let the system take care of them. Never again will you have to waste time performing tasks by hand! Boost Revenues With tailored solutions that draw more customers, save them time, and foster brand loyalty, you can make more money. Keep your security standards high. You can guarantee that the data and payments of your clients are always safe and secure by utilizing cutting-edge technology, such as blockchain encryption.",
      techArr: [
        { src: "/images/menu-icon/shopify.svg", name: "Shopify" },
        { src: "/images/menu-icon/magento.svg", name: "Magento" },
        { src: "/images/menu-icon/woocommerce.svg", name: "WooCommerce" },
        { src: "/images/menu-icon/bigcommerce.svg", name: "BigCommerce" },
        // { src: "/images/menu-icon/opencart.svg", name: "OpenCart" },
      ],
    },
    {
      id: 2,
      title: "UI/UX",
      src: "./images/uncheck.png",
      icon: "./images/ecomIcon/magento.svg",
      iconTitle: "Magento",
      activeimg: "./images/check.png",
      text: "There are numerous benefits to hiring a UI/UX developer. They can aid in making your website or app more user-friendly and intuitive, as well as aid in enhancing the general appearance and feel of your product. Additionally, UI/UX developers can assist you in better understanding your users because they are frequently knowledgeable about user research. In the end, employing a UI/UX developer can assist you in making a better product with a higher chance of success.",
      techArr: [
        { src: "/images/menu-icon/figma.svg", name: "Figma" },
        { src: "/images/menu-icon/adobe-xd.svg", name: "Adobe XD" },
        // { src: "/images/menu-icon/sketch.svg", name: "Sketch" },
        { src: "/images/menu-icon/invision.svg", name: "InVision" },
        // { src: "/images/menu-icon/axure.svg", name: "Axure" },
      ],
    },
    {
      id: 3,
      title: "Mobile App",
      src: "./images/uncheck.png",
      icon: "./images/ecomIcon/magento.svg",
      iconTitle: "Magento",
      activeimg: "./images/check.png",
      text: "You can easily connect with customers, provide prompt service, and keep tabs on business activity with the help of our mobile app. Our mobile application is entirely tailored to meet the particular needs of your business. You can get access to the communications, activities, and information of your business whenever and wherever you need them. A user-friendly interface makes it easy for you to interact with the program, which boosts productivity. Real-time data analysis of your company's operations can help you improve performance.",
      techArr: [
        { src: "/images/menu-icon/flutter.svg", name: "Flutter" },
        { src: "/images/menu-icon/react.svg", name: "React Native" },
        { src: "/images/menu-icon/iconic.svg", name: "Ionic" },
        { src: "/images/menu-icon/java.svg", name: "Java" },
        { src: "/images/menu-icon/swift.svg", name: "Swift" },
        { src: "/images/menu-icon/xman.svg", name: "Xamarin" },
        { src: "/images/menu-icon/cordova.svg", name: "Apache Cordova" },
        { src: "/images/menu-icon/native.svg", name: "Native Script" },
        { src: "/images/menu-icon/jquery.svg", name: "jQuery Mobile" },
        { src: "/images/menu-icon/framework.svg", name: "Framework7" },
        {
          src: "/images/menu-icon/kotlin.svg",
          name: "Kotlin Multiplatform Mobile",
        },
      ],
    },
    {
      id: 4,
      title: "Web Development",
      src: "./images/uncheck.png",
      icon: "./images/ecomIcon/magento.svg",
      iconTitle: "Magento",
      activeimg: "./images/check.png",
      text: "Avigat Technologies provides scalable and reliable technology that adjusts to your company's constantly shifting requirements, from small initiatives to significant ones. We work with you to customize processes and services to fit your unique business model. Allow us to manage the technology so you can focus on your customers. We provide a low-cost option without sacrificing service quality for your IT requirements. Receive first-rate assistance for a fair price. ",
      techArr: [
        { src: "/images/menu-icon/react.svg", name: "React" },
        { src: "/images/menu-icon/angular.svg", name: "Angular" },
        { src: "/images/menu-icon/vue.svg", name: "Vue.js" },
        { src: "/images/menu-icon/node.svg", name: "Node.js" },
        { src: "/images/menu-icon/django.svg", name: "Django" },
        // { src: "/images/menu-icon/rails.svg", name: "Ruby on Rails" },
      ],
    },
    {
      id: 5,
      title: "Game",
      src: "./images/uncheck.png",
      icon: "./images/ecomIcon/magento.svg",
      iconTitle: "Magento",
      activeimg: "./images/check.png",
      text: "You can advance your IT game by playing a game that focuses on IT while coming up with creative and sensible solutions. An exceptional training opportunity for IT professionals to gain crucial knowledge even when they are not working. Develop your IT problem-solving abilities by using plausible scenarios that will make you feel as though you are back at work.",
      techArr: [
        { src: "/images/menu-icon/unity.svg", name: "Unity" },
        { src: "/images/menu-icon/unreal-engine.svg", name: "Unreal Engine" },
        {
          src: "/images/menu-icon/light-gamemaker.svg",
          name: "GameMaker Studio",
        },
      ],
    },
    {
      id: 6,
      title: "Dev-Ops",
      src: "./images/uncheck.png",
      icon: "./images/ecomIcon/magento.svg",
      iconTitle: "Magento",
      activeimg: "./images/check.png",
      text: "Utilize the solution from Avigat Technologies to drastically shorten development and time-to-market cycles, saving both time and money. Automated task procedures can help you maintain smooth operations by freeing up resources and allowing your staff to concentrate on more strategic goals. Solutions from Avigat Technologies are designed for businesses of all sizes, making it simple to manage business growth and expand capacity as necessary.",
      techArr: [
        { src: "/images/menu-icon/docker.svg", name: "Docker" },
        { src: "/images/menu-icon/kubernetes.svg", name: "Kubernetes" },
        { src: "/images/menu-icon/jenkins.svg", name: "Jenkins" },
        { src: "/images/menu-icon/ansible.svg", name: "Ansible" },
        { src: "/images/menu-icon/terraform.svg", name: "Terraform" },
      ],
    },
    {
      id: 7,
      title: "Cloud",
      src: "./images/uncheck.png",
      icon: "./images/ecomIcon/magento.svg",
      iconTitle: "Magento",
      activeimg: "./images/check.png",
      text: "You can be sure that the data for your business is safe and reliable with Avigat's cloud hosting options. By using Avigat's cloud solutions, you could avoid spending as much on pricey hardware and instead make more significant investments, gain access to additional IT resources as needed, and manage your business operations quickly and efficiently.",
      techArr: [
        { src: "/images/menu-icon/aws.svg", name: "AWS" },
        { src: "/images/menu-icon/azure.svg", name: "Azure" },
        { src: "/images/menu-icon/google.svg", name: "Google Cloud Platform" },
      ],
    },
  ];
  console.log(img1, "img");
  const client = [
    { id: 1, src: img1 },
    { id: 2, src: img2 },
    { id: 3, src: img3 },
    { id: 4, src: img4 },
    { id: 5, src: img5 },
    { id: 6, src: img6 },
    { id: 7, src: img7 },
    { id: 8, src: img8 },
  ];
  const services = [
    {
      id: "1",
      pathname: "/software-development",
      title: "Software Development",
      src: "./images/software-icon.svg",
    },
    {
      id: "2",
      pathname: "/website-development",
      title: "Website Development",
      src: "./images/website-icon.svg",
    },
    {
      id: "3",
      pathname: "/mobile-development",
      title: "Mobile App Development",
      src: "./images/mobile-icon.svg",
    },
    {
      id: "4",
      pathname: "/digital-marketing",
      title: "Digital Marketing",
      src: "./images/digital-marketing-icon.svg",
    },
    {
      id: "5",
      pathname: "/crm-system",
      title: "CRM System",
      src: "./images/crm-icon.svg",
    },
    {
      id: "6",
      pathname: "/ui-ux-design",
      title: "UI/UX Design",
      src: "./images/uiux-icon.svg",
    },
    {
      id: "7",
      pathname: "/software-security",
      title: "Software Security",
      src: "./images/software-security-icon.svg",
    },
    {
      id: "8",
      pathname: "/web-hosting",
      title: "Web Hosting",
      src: "./images/web-hosting-icon.svg",
    },
  ];
  const handleClick = (ele) => (setActive(ele?.id), setActiveData(ele));
  const text =
    "Increase profitability with Pyndertech. With Solution For IT Business, you can gain a competitive edge. We've customized our tools to meet your unique needs, giving you the boost you need to maximize your sales. Using the simple and comprehensive user interface of Solution for IT Business, you can accomplish more in less time, saving time and money on staff.".split(
      " "
    );
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation every time the element is in view
    threshold: 0.1, // Trigger when 10% of the element is in the viewport
  });

  return (
    <>
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 2, ease: "easeOut" }}
              >
                <span>Solution</span> For IT Business
              </motion.h1>

              <p>
                {text.map((word, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.05 }}
                    style={{ display: "inline-block", marginRight: "5px" }} // Adjust spacing between words
                  >
                    {word}
                  </motion.span>
                ))}
              </p>
              <Link to="/about">
                Learn More <img src="/images/arrow_left.svg" alt="" />
              </Link>
            </div>
            <div className="col-lg-6 d-none d-md-block d-lg-block">
              <div className="banner">
                <img
                  src="./images/rocket.gif"
                  className="img-fluid"
                  alt="rocket-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="on_demand_services">
        <div className="container">
          <div className="on_demand_services_box">
            <img src="./images/large_arrow.svg" alt="" />
            <div className="on_ser_inner">
              {/* <h2>On-Demand Services</h2> */}
              <motion.h2
                ref={ref}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
                transition={{ duration: 1, ease: "easeOut" }}
              >
                On-Demand Services
              </motion.h2>
              <div className="on_ser_main">
                {services.map((ele, i) => {
                  return (
                    <Link to={ele?.pathname} className="on_ser_item" key={i}>
                      <p>{ele?.title}</p>

                      <figure>
                        <motion.img
                          src={ele?.src}
                          alt="development images"
                          initial={{ rotate: 0 }}
                          animate={{ rotate: 360 }}
                          transition={{
                            rotate: {
                              repeat: Infinity,
                              repeatType: "loop",
                              duration: 2,
                              ease: "linear",
                            },
                          }}
                          style={{ width: "100%", height: "auto" }} // Adjust sizing as needed
                        />
                      </figure>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <figure>
                <img src="./images/arrow-circle.svg" alt="arrow icon" />
              </figure>
              <figure>
                <img src="./images/thumb-icon.svg" alt="thumb icon" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="buildSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="build_item build_cnt">
                <h3>
                  Build eCommerce apps and websites seamlessly with Pyndertech
                </h3>
                <p>
                  Pyndertech uses dependable cloud technology that can handle
                  heavy traffic, security, and scalability demands in order to
                  help your clients move more quickly.{" "}
                </p>
                <Link to="/services">
                  Learn More <img src="./images/arrow_left_sm.svg" alt="" />
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="build_item text-end">
                <div className="bTitle">
                  <h2>Build</h2>
                  <p>Project</p>
                </div>
                <figure className="pt-lg-4 pe-lg-3">
                  <img
                    src="./images/build_project.svg"
                    className="img-fluid"
                    alt="tools image"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about_company">
        <div className="container position-relative">
          <div className="row g-0">
            <div className="col-lg-6">
              <div className="about_us">
                <span>ABOUT COMPANY</span>
                <h3>IT Solutions, You Name it, We have it</h3>
                <p>
                  At Avigat Technologies (Pyndertech), we use technology to help
                  businesses succeed. Our team of skilled developers has
                  extensive experience in AI, cloud computing, cybersecurity,
                  digital transformation, software development, data analytics,
                  IT consulting, and blockchain. We create custom solutions that
                  fit each client's unique needs, ensuring they achieve their
                  goals with the best technology available.
                </p>
                <div className="s_box">
                  <ul>
                    <li>
                      <img src="./images/s_icon-1.svg" alt="desktop image" />
                    </li>
                    <li>
                      <img src="./images/s_icon-2.svg" alt="mobile image" />
                    </li>
                    <li>
                      <img src="./images/s_icon-3.svg" alt="desktop image 2" />
                    </li>
                    <li>
                      <img src="./images/s_icon-4.svg" alt="rocket image" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none"></div>
          </div>
          <div className="row g-0">
            <div className="col-lg-6 text-end d-none d-lg-block">
              <div className="illustrater">
                <img src="./images/illustration.png" alt="illustraion image" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about_us">
                <span>ABOUT COMPANY</span>
                <h3>Exceptional Website Design and Development</h3>
                <p>
                  Keep Your Competitive Advantage, Utilize the newest technology
                  and trends on your website to stand out from the
                  competition.By using appealing designs that are intended to
                  create an unforgettable experience
                </p>
                <div className="s_box_2">
                  <ul>
                    <li>
                      <img src="./images/arrow-smll-2.svg" alt="arrow icon" />
                      Modern and Responsive Designs{" "}
                    </li>
                    <li>
                      <img src="./images/arrow-smll-2.svg" alt="arrow icon" />{" "}
                      Innovative Web Design{" "}
                    </li>
                    <li>
                      <img src="./images/arrow-smll-2.svg" alt="arrow icon" />
                      {/* Speed and  */}
                      Performance Optimization
                    </li>
                    <li>
                      <img src="./images/arrow-smll-2.svg" alt="arrow icon" />{" "}
                      Custom Development Solutions
                    </li>
                    <li>
                      <img src="./images/arrow-smll-2.svg" alt="arrow icon" />{" "}
                      Secure and Reliable{" "}
                    </li>
                    <li>
                      <img src="./images/arrow-smll-2.svg" alt="arrow icon" />{" "}
                      SEO-Friendly Architecture{" "}
                    </li>
                    <li>
                      <img src="./images/arrow-smll-2.svg" alt="arrow icon" />{" "}
                      E-Commerce Integration{" "}
                    </li>
                    <li>
                      <img src="./images/arrow-smll-2.svg" alt="arrow icon" />{" "}
                      Continuous Support
                      {/* and Maintenance{" "} */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="team_section">
        <div className="container">
          <div className="team_title">
            <span>EXCLUSIVE MEMBERS</span>
            <h3>Meet Our Experience Team Members</h3>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="team_box">
                <figure>
                  <img src="./images/team-4.png" alt="" />
                </figure>
                <h3>Wallace S. Camacho</h3>
                <p>Business Manager</p>
                <Link to="">
                  <img src="./images/read_more.svg" alt="" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="team_box">
                <figure>
                  <img src="./images/team-4.png" alt="" />
                </figure>
                <h3>Wallace S. Camacho</h3>
                <p>Business Manager</p>
                <Link to="">
                  <img src="./images/read_more.svg" alt="" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="team_box">
                <figure>
                  <img src="./images/team-4.png" alt="" />
                </figure>
                <h3>Wallace S. Camacho</h3>
                <p>Business Manager</p>
                <Link to="">
                  <img src="./images/read_more.svg" alt="" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="team_box">
                <figure>
                  <img src="./images/team-4.png" alt="" />
                </figure>
                <h3>Wallace S. Camacho</h3>
                <p>Business Manager</p>
                <Link to="">
                  <img src="./images/read_more.svg" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="how_can_help">
        <div className="container">
          <div className="team_title">
            <span>HOW CAN HELP YOU</span>
            <h3>We Help Your IT Business</h3>
          </div>
          <div className="item_container">
            <div className="it_item">
              <h3>IT Consultancy</h3>
              <p>Faster & Smarter Solutions</p>
            </div>
            <div className="it_item">
              <h3>Cyber Security</h3>
              <p>Faster & Smarter Solutions</p>
            </div>
            <div className="it_item">
              <h3>Development</h3>
              <p>Faster & Smarter Solutions</p>
            </div>
            <div className="it_item">
              <h3>UI/UX Strategy</h3>
              <p>Faster & Smarter Solutions</p>
            </div>
          </div>
        </div>
      </section>
      <section className="info_tech d-none d-xl-block">
        <div className="container">
          <div className="d-flex info_text">
            <div className="info_title">
              <p>Information</p>
              <h3>Technology</h3>
            </div>
            <div className="info_content">
              <ul>
                <li>
                  Information technology encompasses the use of computers,
                  telecommunications, and software to manage and process
                  information. It includes hardware (like computers and
                  servers), software (applications and operating systems),
                  networks (internet and intranet), and services (such as IT
                  support and cybersecurity).
                </li>
                <li>
                  IT plays a crucial role in enabling digital transformation
                  across industries. Businesses leverage IT to automate
                  processes, improve efficiency, enhance communication, and
                  innovate products and services.{" "}
                </li>
                <li>
                  As businesses and individuals increasingly rely on digital
                  platforms, cybersecurity becomes paramount. IT professionals
                  implement measures to protect data from unauthorized access,
                  breaches, and cyber threats.
                </li>
                <li>
                  Rapid advancements in IT introduce new technologies like
                  artificial intelligence (AI), machine learning, blockchain,
                  and the Internet of Things (IoT). These innovations drive new
                  opportunities and challenges for businesses and society.
                </li>
                <li>
                  IT offers diverse career paths, including roles in software
                  development, cybersecurity, data analysis, IT infrastructure
                  management, cloud computing, and digital marketing. Continuous
                  learning and adaptation are essential due to the fast-paced
                  nature of the field.
                </li>
                <li>
                  Cloud computing has revolutionized how businesses store data,
                  deliver services, and manage IT resources. It enables
                  organizations to access computing power, storage, and software
                  applications over the internet on a pay-as-you-go basis. Cloud
                  computing services range from Infrastructure as a Service
                  (IaaS) to Platform as a Service (PaaS) and Software as a
                  Service (SaaS), providing scalability, flexibility, and
                  cost-efficiency for businesses of all sizes.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="best_website d-none d-md-block">
        <div className="container text-center">
          <h4>The Best Website Designing and Development</h4>
          <p>
            <b>Let our design thinking guide innovation at your workplace.</b>
            Company in Delhi ensures that the whole technical part is flawlessly
            managed and compliments the creative design of web pages with fast
            loading speed and error-free functioning. Every little design
            element and detail of the{" "}
          </p>
          <div className="quest">
            <img src="./images/question.png" className="img-fluid" alt="" />
          </div>
        </div>
      </section>
      <section className="serSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="serBox">
                <ul>
                  {data?.map((ele, i) => {
                    const isActive = ele.id === active;
                    return (
                      <li key={i}>
                        <button onClick={() => handleClick(ele)}>
                          {isActive ? (
                            <img src={ele?.activeimg} alt="promotion banner" />
                          ) : (
                            <img src={ele?.src} alt="promotion banner" />
                          )}
                          <span style={{ color: isActive && "blue" }}>
                            {ele?.title}
                          </span>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="col-lg-8">
              {active && (
                <div className="cntBox">
                  <h4>{activeData?.title}</h4>
                  <p>{activeData?.text}</p>
                  <ul className="d-flex logoList flex-wrap gap-2">
                    {activeData.techArr.map((item, i) => (
                      <li key={i}>
                        {" "}
                        {/* Use a unique key here, consider using a unique identifier if available */}
                        <figure className="tech-icons-containers">
                          <img src={item.src} alt={item.name} />{" "}
                          {/* Access icon from item */}
                          {/* <FaNodeJs className="text-white fs-1" />{" "} */}
                          {/* Assuming you want to display an icon */}
                        </figure>
                        <h5>{item.name}</h5> {/* Access name from item */}
                      </li>
                    ))}
                  </ul>
                  <Link to="">
                    <img src="./images/read_more.svg" alt="" />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="our_client">
        <div className="container">
          <div className="team_title text-center mb-lg-5 mb-3">
            <h3>Our Client</h3>
            <p>Let our design thinking guide innovation at your workplace.</p>
          </div>
          <div className="cl_main">
            {client?.map((ele, i) => {
              return (
                <div className="cl_logo" key={i}>
                  <img src={ele?.src} alt="" />
                  {/* <img src="../../clientimages/img1.png" alt="" /> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* <section className="project_detail d-none d-lg-block">
        <div className="container">
          <ul className="project_list">
            <li>
              <div className="sm_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
            <li>
              <div className="large_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
            <li>
              <div className="sm_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
            <li>
              <div className="medium_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
            <li>
              <div className="sm_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
            <li>
              <div className="large_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
            <li>
              <div className="sm_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
            <li>
              <div className="medium_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
            <li>
              <div className="sm_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
            <li>
              <div className="large_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
            <li>
              <div className="sm_box">
                <h6>368+</h6>
                <p>Projects</p>
              </div>
            </li>
          </ul>
        </div>
      </section> */}

      <section className="blog">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between blog_title">
            <h3>Blogs</h3>
            <Link to="/blog">View All</Link>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <figure>
                <img
                  src="./images/blog-1.png"
                  className="img-fluid"
                  alt="Angular: Web development framework that is used to build
                  mobile and web applications"
                />
              </figure>
              <div className="blog_cnt">
                <span>August 22, 2024</span>
                <h5>
                  Angular: Web development framework that is used to build
                  mobile and web applications
                </h5>
                <p>
                  Angular is a popular web development framework that is used to
                  build mobile and web applications. It is used to create online
                  experiences that are secure, robust, interactive, and
                  impressive. It uses HTML and TypeScript to create powerful
                  client-side applications.
                </p>
                <Link to="/blogdetail/angular">
                  Read Guide <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src="./images/blog-2.png"
                    className="img-fluid"
                    alt="Database As a Service Cloud-Based Product from Microsoft"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="blog_cnt">
                    <span>August 02, 2024</span>
                    <h5>
                      Database As a Service Cloud-Based Product from Microsoft
                    </h5>
                    <p>
                      Microsoft Azure SQL Database is a cloud-based database
                      solution that brings the power of its predictive engine to
                      help businesses host high-performance applications and
                      databases in the cloud.
                    </p>
                    <Link to="/blogdetail/database">
                      Read Guide <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-6">
                  <img
                    src="./images/blog-2.png"
                    className="img-fluid"
                    alt="python blog"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="blog_cnt">
                    <span>October 07, 2024</span>
                    <h5>Vue.js: Build Powerful Web Applications</h5>
                    <p>
                      Vue.js is a JavaScript framework used to build powerful,
                      fast, and versatile single page web applications and
                      dynamic user experiences, from simple e-commerce
                      storefronts to dashboards and components.
                    </p>
                    <Link to="/blogdetail/vuejs">
                      Read Guide <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ecom_main">
        <div className="container">
          <div className="ecome_app">
            <div className="row">
              <div className="col-lg-7">
                <div className="ecom_inner">
                  <h4>
                    Build eCommerce apps and websites seamlessly with Pyndertech
                  </h4>
                  <p>
                    Sed ut perspiciatis unde omnis natus error sit voluptatem
                    accusa ntium doloremque laudantium totam rem aperiamea
                    queipsa quae abillo inventore{" "}
                  </p>
                  <Link to="/services">
                    Learn More <BsArrowRight />
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 d-flex align-self-end d-none d-lg-block">
                <div className="objImg">
                  <img
                    className="img-fluid"
                    src="./images/object.svg"
                    alt="banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
