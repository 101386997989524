import StartProjectCard from "../AllCards/StartProjectCard";

function Careers() {
  return (
    <>
      <section className="caarer">
        <div className="container">
          <div className="careTitle">
            <img src="./images/graphic_img.svg" alt="" />
            <h1>Careers</h1>
            <p>
              Website development involves creating, building, publishing, and
              maintaining a website for the world-wide-web (internet) or private
              network (intranet). Using many open-source available tools, we can
              develop a website with simple static content, or build complex web
              applications, offer services, and run commerce businesses.
            </p>
          </div>
        </div>
        <div className="container pt-lg-4 pt-3">
          <div className="row car_img">
            <div className="col-md-6 col-lg-3">
              <img
                src="./images/car_1.png"
                className="img-fluid"
                alt="career image"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <img
                src="./images/car_2.png"
                className="img-fluid"
                alt="career image"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <img
                src="./images/car_3.png"
                className="img-fluid"
                alt="career image"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <img
                src="./images/car_4.png"
                className="img-fluid"
                alt="career image"
              />
            </div>
          </div>
        </div>
        <div className="container pt-lg-5 pt-3">
          <div className="row">
            <div className="col-md-12">
              <div className="create_website pb-lg-5">
                <h2>Software Development</h2>
                <p>
                  Software development penetrates all the significant aspects of
                  our life in this digital age. Individuals, startups, and big
                  enterprises rely upon different software to carry out their
                  daily tasks. There is no doubt that enterprise software
                  development has the highest growth rate compared to every
                  other tech industry. Besides, Software development is
                  projected to outgrow in 2023. According to a report from
                  Statista, Global spending on enterprise software development
                  will touch 755bn USD in 2023. Yes! That’s Billions with ‘B.’
                </p>
              </div>
            </div>
          </div>
          <div className="row car_img">
            <div className="col-md-6">
              <img
                src="./images/car_5.png"
                className="img-fluid"
                alt="career image"
              />
            </div>
            <div className="col-md-6 space_img">
              <img src="./images/car_6.png" className="img-fluid" alt="career image" />
            </div>
          </div>
        </div>
      </section>
      <section className="space_bottom">
        <div className="container">
          <div className="create_website pb-lg-5">
            <h2>Life at Pyndertech is fun</h2>
            <p>
              “Life is all about countless memories” Work with fun is the core
              mantra for Webkulites. We believe in creating a journey of
              cherishing moments. Join and experience the worth dive into our
              world. Learn, Laugh, and Grow with us!
            </p>
          </div>
          <div className="img_galley">
            <div>
              <img src="./images/car_7.png" className="img-fluid" alt="career image" />
            </div>
            <div>
              <img src="./images/car_8.png" className="img-fluid" alt="career image" />
            </div>
            <div>
              <img src="./images/car_9.png" className="img-fluid" alt="career image" />
            </div>
            <div>
              <img src="./images/car_10.png" className="img-fluid" alt="career image" />
            </div>
          </div>
        </div>
      </section>
      <section className="space_bottom">
        <div className="container">
          <div className="create_website pb-lg-5">
            <h2>Why join Pyndertech?</h2>
            <p>
              We believe in “growing up the talent rather than buying one” We
              Love to contribute and build talents in the industry since 2010.
              Be a part of our 650+ strong army working every day to make
              E-Commerce easier. Be valued and Skilled!
            </p>
          </div>
          <div className="img_galley">
            <div>
              <img src="./images/car_7.png" className="img-fluid" alt="career image" />
            </div>
            <div>
              <img src="./images/car_8.png" className="img-fluid" alt="career image" />
            </div>
            <div>
              <img src="./images/car_9.png" className="img-fluid" alt="career image" />
            </div>
            <div>
              <img src="./images/car_10.png" className="img-fluid" alt="career image" />
            </div>
          </div>
        </div>
      </section>
      <section className="space_bottom">
        <div className="container">
          <div className="create_website pb-lg-5">
            <h2>Handful of benefits and perks</h2>
            <p>
              We care for every individual associated with us. Numerous perks
              and facilities of necessities helps to maintain comfort and
              work-life balance. Coordination of Health and Work is maintained
              while working in Pyndertech.
            </p>
          </div>
          <div className="galley_icon">
            <div>
              <figure>
                <img
                  src="./images/health_icon.svg"
                  className="img-fluid"
                  alt="career image"
                />
              </figure>
              <figcaption>Health Insurance</figcaption>
            </div>
            <div>
              <figure>
                <img
                  src="./images/vaction_icon.svg"
                  className="img-fluid"
                  alt="Vacation"
                />
              </figure>
              <figcaption>Vacation</figcaption>
            </div>
            <div>
              <figure>
                <img src="./images/gym_icon.svg" className="img-fluid" alt="Gym" />
              </figure>
              <figcaption>Gym</figcaption>
            </div>
            <div>
              <figure>
                <img
                  src="./images/timing_icon.svg"
                  className="img-fluid"
                  alt="Flexible Timing"
                />
              </figure>
              <figcaption>Flexible Timing</figcaption>
            </div>
            <div>
              <figure>
                <img
                  src="./images/food_icon.svg"
                  className="img-fluid"
                  alt="Beverages"
                />
              </figure>
              <figcaption>Beverages</figcaption>
            </div>
            <div>
              <figure>
                <img
                  src="./images/policy_icon.svg"
                  className="img-fluid"
                  alt="Paid Leave Policy"
                />
              </figure>
              <figcaption>Paid Leave Policy</figcaption>
            </div>
          </div>
        </div>
      </section>
      <StartProjectCard />
    </>
  );
}

export default Careers;
