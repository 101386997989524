import React from "react";
import AllServicesHeader from "../ServiceHeader/AllServicesHeader";
import FaqCard from "../AllCards/FaqCard";
import StartProjectCard from "../AllCards/StartProjectCard";
import TechInfo from "../TechInfo";
import KeyPoints from "../KeyPoints";
import { BsCheck } from "react-icons/bs";
function CRMDetail(){
    const data = [
        {
          id: "1",
          title: "Usability",
          icon: "./images/usability_icon.svg",
          desc: "The fact that teams are uncomfortable with utilizing new technology is frequently what prevents firms from embracing it. As a result, it's critical to ensure that the CRM you employ has a clear interface, an excellent user experience, and is simple to learn for everyone who will be using it.",
          alt: "Usability icon"
        },
        {
          id: "2",
          title: "Scalability",
          icon: "./images/scalability_icon.svg",
          desc: "If your company has aggressive year-on-year growth goals, the CRM must be able to develop alongside your teams and business by enabling extra modules and bespoke procedures, as well as connecting to any third-party apps you may be using.",
          alt: "Scalability icon"
        },
        {
          id: "3",
          title: "Security",
          icon: "./images/security_icon.svg",
          desc: "With data security worries at an all-time high, a cloud CRM with safe data centers and industry-standard security measures enables you to keep doing business without fear of your customer data being hacked.",
          alt: "Security icon"
        },
        {
          id: "4",
          title: "Customization",
          icon: "./images/customization_icon.svg",
          desc: "Every company's approach and requirements are distinct. A CRM that can be easily changed to meet those demands would be ideal for your business. A CRM that can be customized to match your company operations by adding new modules, developing custom processes, using workflows to automate repetitive activities, and more.",
          alt: "Customization icon"
        },
        {
          id: "5",
          title: "Mobility",
          icon: "./images/mobility_icon.svg",
          desc: "We now live in a time when mobile internet traffic outnumbers desktop internet traffic. If you have a field sales staff, they must have constant access to client data. This enables them to be mobile, complete transactions more successfully, and keep everyone informed.",
          alt: "Mobility icon"
        },
        {
          id: "6",
          title: "Migration",
          icon: "./images/migration_icon.svg",
          desc: "If you have all of your data on excel sheets or are using another CRM, you should be able to easily integrate it into the new CRM software and get your company up and operating as quickly as possible. For the CRM that you wish to deploy, this conversion procedure must be quick and simple.",
          alt: "Migration icon"
        }
      ];
      
const benefit=[
    {id:"1",title:"Boost sales", desc:"A CRM may assist you in identifying, nurturing, and following up on qualified prospects. This means you don't waste time or money on a prospect who is unlikely to become a customer. When combined with a shortened sales cycle, you can direct more leads more efficiently and convert them to delighted clients in much less time."},
    {id:"2",title:"Efficient business process", desc:"Help your team stay busy by creating and implementing effective business procedures. A CRM may assist you in ensuring that standards are met when procedures are carried out. By automating common operations, you can ensure that your sales team's time is focused on closing transactions rather than on monotonous duties."},
    {id:"3",title:"Tactical decision-making", desc:"Accurate data from throughout your business and keeps your decision-makers informed, allowing them to make smarter decisions in response to market developments. Detailed reports will demonstrate how your teams are doing and where they may improve."},
    {id:"4",title:"Data security and compliance", desc:"You can be confident that your client data is secure in your vendor's data centers, which are protected by cutting-edge physical and digital security measures. If you are headquartered in the European Union or do business with European clients, a GDPR-compliant CRM will be one of your most valuable assets."},
    {id:"5",title:"Mobile accessibility", desc:"A CRM with a mobile app gives your staff access to critical CRM data no matter where they are. A sales professional in the field may use their mobile app to look up information about local clients, drop by for a meeting, and instantly update everyone else on any new information by entering it in the CRM."},
    {id:"6",title:"Customer satisfaction", desc:"A CRM provides your team with all of a customer's historical data, as well as information about similar issues encountered by others. A centralized repository of information allows your team to quickly resolve customer inquiries, ensuring that they leave satisfied."},
]
    return(
        <>
            <AllServicesHeader/>
            <section className="space_top">
                <div className="container">
                    <div className="crm_image">
                        <div className="crm_stra">
                            <h2>CRM - The Strategy</h2>
                            <p>CRM strategy is the concept of how firms handle customer interactions and create connections with customers. It entails gathering customer data and evaluating the history of customer interactions to deliver better services and establish stronger relationships with customers to boost sales and income.</p>
                        </div>
                        <div className="d-none d-lg-block my-auto"><img src="./images/crm_img.svg" className="img-fluid" alt="" /></div>
                    </div>
                </div>
            </section>
            <section className="website_dev space_top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 my-auto">
                            <div className="create_website">
                                <h2>Why do you need a <br/>CRM strategy?</h2>
                                <p>Without a solid CRM approach, your customer information will be disorganized, making it difficult to keep track of all the contact points and interactions you've had with specific clients. This results in a lengthy sales cycle and a generic and subpar consumer experience.</p>
                            </div>
                        </div>
                        <div className="col-lg-6"><img src="./images/img-2.png" className="img-fluid shadow_img" alt="" /></div>
                    </div>
                </div>
            </section>
            <section className="website_dev">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 my-auto">
                            <div className="create_website">
                                <h2>Building great customer <br/> relations</h2>
                                <p>Great client relationships are built on your organization's consistent and customized experience, regardless of where a customer is in your sales cycle. This implies that every team in your business, from marketing to sales to support, must provide a consistent customer experience.</p>
                                <p>When your company is new and you're handling all of your client information on spreadsheets, this may be feasible, but it's not recommended. The time spent on data input may be better spent on attracting consumers and closing agreements. This becomes considerably more challenging when your company grows and your consumer base expands. This is where CRM software might come in handy.</p>
                            </div>
                        </div>
                        <div className="col-lg-6"><img src="./images/img-2.png" className="img-fluid shadow_img" alt="" /></div>
                    </div>
                </div>
            </section>
            <section className="crm_soft">
                <div className="container">
                    <div className="crm_box">
                        <div className="p-5 crm_soft_title ">
                            <h3>CRM-The Software</h3>
                            <p>Modern CRM system software may be traced back to the late 1980s and early 1990s when Contact Management Systems (CMS) were developed, which later evolved into Sales Force Automation (SFA) technology. As the sector evolved, vendors were driven to provide a greater range of services, including marketing, presales, and support.</p>
                        </div>
                        <div className="text-end align-bottom"><img src="./images/icons.svg" alt="" /></div>
                    </div>
                </div>
            </section>
            <section className="black_bg">
                <KeyPoints title="Why your business needs CRM Software" desc="If you're having trouble managing sales, marketing, and customer interactions, it might be time to invest in sophisticated, all-inclusive business software. If you're on the fence about using a Customer Relationship Management solution, keep an eye out for the following warning signs:"/>
                <section className="d-none d-xl-none">
                        <div className="container">
                            <div className="crm d-flex align-items-center justify-content-between">
                                <div className="crm_info">Crm</div>
                                <ul className="d-flex crm_list">
                                    <li><div className="txt">MARKETING</div><div className="txt">ANALYSIS</div></li>
                                    <li><div className="txt">STRATEGY</div><div className="txt">COMMUNICATION</div></li>
                                    <li><div className="txt">LEAD</div><div className="txt">SUPPORT</div></li>
                                    <li><div className="txt">SERVICE</div><div className="txt">SALES</div></li>
                                </ul>
                                <div className="orde_title">ORDER</div>
                            </div>
                        </div>
                </section>
                <section>
                <div className="container">
                <div className="key_title pt-lg-5 pt-3">
                    <h3 className="text-white">Why your business needs CRM Software</h3>
                    <p className="text-white">If you're having trouble managing sales, marketing, and customer interactions, it might be time to invest in sophisticated, all-inclusive business software. If you're on the fence about using a Customer Relationship Management solution, keep an eye out for the following warning signs:</p>
                </div>
                </div>
                <div className="container">
                    <div className="bg-white rounded-5 mt-lg-5">
                        <div className="chose_crm_main">
                            <div className="chose_item">
                                <h5>Cloud CRM</h5>
                                <ul>
                                    <li><BsCheck /><p> On their servers, the service provider develops and maintains cloud CRM software. The service is available through the internet via a browser, allowing you to deploy it without installing any software on the machines in your workplace.</p></li>
                                </ul>
                            </div>
                            <div className="chose_item">
                                <h5>On-premise CRM</h5>
                                <ul>
                                    <li><BsCheck /><p> On-premise CRM often entails the installation of servers in your workplace as well as the software on all essential devices on your local network. This places complete responsibility for upgrades, customization, and maintenance on your IT department.</p></li>
                                </ul>
                            </div>
                            <div className="chose_item">
                                <h5>INFRASTRUCTURE</h5>
                                <ul>
                                    <li><BsCheck /><p> Other than a browser and an active internet connection, no extra infrastructure is required.</p></li>
                                    <li><BsCheck /><p> You must install servers on your company's facilities.</p></li>
                                </ul>
                            </div>
                            <div className="chose_item">
                                <h5>ACCESSIBILITY</h5>
                                <ul>
                                    <li><BsCheck /><p> Your CRM data is available from any location and device. CRM suppliers offer a mobile app for access while on the go.</p></li>
                                    <li><BsCheck /><p> Your data is kept on local servers and can only be accessed by devices connected to your local network. Building mobile applications need additional effort and financial investment.</p></li>
                                </ul>
                            </div>
                            <div className="chose_item">
                                <h5>TRIAL</h5>
                                <ul>
                                    <li><BsCheck /><p> Cloud CRM provides free trials so that you may try the solution and determine whether it suits your requirements.</p></li>
                                    <li><BsCheck /><p> There is no trial available since the program requires on-premises infrastructure to execute in the first place.</p></li>
                                </ul>
                            </div>
                            <div className="chose_item">
                                <h5>COST</h5>
                                <ul>
                                    <li><BsCheck /><p> A pay-as-you-go plan allows you to pay an upfront membership cost on a monthly or annual basis for your total number of subscribers.</p></li>
                                    <li><BsCheck /><p> A significant upfront cost for server and software installation, as well as a monthly software license price.</p></li>
                                </ul>
                            </div>
                            <div className="chose_item">
                                <h5>CUSTOMIZATION</h5>
                                <ul>
                                    <li><BsCheck /><p> Customization possibilities vary depending on the provider, however, they are frequently easily available and easy to implement.</p></li>
                                    <li><BsCheck /><p> The deployment phase is longer and requires more manpower.</p></li>
                                </ul>
                            </div>
                            <div className="chose_item">
                                <h5>INTEGRATION</h5>
                                <ul>
                                    <li><BsCheck /><p> Simple integration with various corporate systems and APIs.</p></li>
                                    <li><BsCheck /><p> Depending on the seller, professional expertise and additional fees are required.</p></li>
                                </ul>
                            </div>
                            <div className="chose_item">
                                <h5>SECURITY</h5>
                                <ul>
                                    <li><BsCheck /><p> Vendors are committed to providing strong security and access control to safeguard the safety and integrity of your data.</p></li>
                                    <li><BsCheck /><p> Your business will be in charge of monitoring and protecting user data. You will need to devote a staff as well as significant resources to keeping your security and privacy procedures up to date.</p></li>
                                </ul>
                            </div>
                        </div>
                        <div className="p-5">
                            <p>A decade ago, on-premise CRMs were the preferred option of enterprises, with 88% of purchasers preferring on-premise CRM over cloud CRM software. However, the tide has shifted, with 87% of organizations preferring a cloud CRM tool over an on-premise system.</p>
                            <p>Cloud CRM software is the way to go if you want your data to be accessible at all times and have the capacity to scale up and down with ease. It's also easier for small firms to implement a cloud CRM and become more productive without breaking the bank.</p>
                        </div>
                    </div>
                </div>
            </section>
            </section>
            <section className="crm_things">
                <div className="container">
                    <div className="key_title pb-lg-5 pb-3">
                        <h3>Things to consider when implementing a CRM</h3>
                        <p>Before committing to purchase the best CRM software for your company, consider the following:</p>
                    </div>
                    <div className="row">
                        {data.map((ele,i)=>{
                            return(
                                <div className="col-md-6 col-lg-4 crm_cnt" key={i}>
                                    <img src={ele.icon} alt={ele?.alt} />
                                    <h3>{ele.title}</h3>
                                    <p>{ele.desc}</p>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>
            <section className="crm_soft">
                <div className="container">
                    <div className="crm_box app_title align-items-center">
                        <div className="p-5 crm_soft_title">
                            <h3>Build <span className="text-primary">Software</span> apps and <span className="text-white">websites</span></h3>
                        </div>
                        <div className="text-end align-bottom"><img src="./images/chip_image.svg" alt="" /></div>
                    </div>
                </div>
            </section>
            <section className="benefit">
                <div className="container">
                    <h4>Benefits of CRM software systems</h4>
                    <ul>
                        {benefit.map((ele,i)=>{
                            return(
                            <li key={i}>
                                <h5>{ele.title}</h5>
                                <p>{ele.desc}</p>
                            </li>
                            )
                        })}
                        
                    </ul>
                </div>
            </section>
                 <div className="container">
                    <div className="img_box"><img src="./images/web_img.png" className="img-fluid" alt="website image" /></div>
                 </div>       
            <section className="sale_buss">
                <section>
                    <div className="container">
                        <div className="key_title pb-lg-5 pb-3">
                            <h3>Build an effective sales funnel and pipeline for your business</h3>
                            <p>One of the most important components of a successful CRM system is its ability to assist your team in developing and refining your sales process through an effective sales funnel and sales pipeline. Your sales funnel and pipeline, while sometimes confused, play separate but equally important responsibilities in converting inbound leads to paying clients.</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="sale_pipeline">
                                    <h4>Sales funnel vs sales pipeline</h4>
                                    <p>A sales pipeline attempts to connect the many stages of the sales process, beginning with contact, qualification, meeting, and proposal and ending with contract completion. A sales funnel, on the other hand, documents the whole client experience from start to finish. They each have unique benefits and functions.</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 sale_pipe">
                                        <figure><img src="./images/sales_pipeline_1.svg" alt="sales pipeline" /></figure>
                                        <h5>Sales pipeline</h5>
                                        <p>Displays the number, value, and stage of your open transactions. Analyzing a sales pipeline allows you to better understand deal activity during the sales process and allows a sales team to make educated decisions about which elements to prioritize in order to achieve greater conversion rates.</p>
                                    </div>
                                    <div className="col-lg-6 sale_pipe">
                                        <figure><img src="./images/sales_pipeline_2.svg" alt="sales pipeline 2" /></figure>
                                        <h5>Sales funnel</h5>
                                        <p>Conversion rates are displayed throughout the sales process. You can monitor which stage your leads are most likely to abandon and allocate resources to plug leakage.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>            
            <FaqCard/>
            <StartProjectCard/>
        </>
    )
}

export default CRMDetail;