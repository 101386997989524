import React from "react";
import { Link } from "react-router-dom";
import StartProjectCard from "../AllCards/StartProjectCard";
function Services() {
  const data = [
    {
      id: "1",
      pathname: "/software-development",
      title: "Software Development",
      alt: "Software Development Icon",
      icon: "./images/s_software.svg",
      des: "implementation company with a team of certified professionals offering the best salesforce certified professionals offering the best",
    },
    {
      id: "2",
      pathname: "/website-development",
      title: "Website Development",
      alt: "Website Development Icon",
      icon: "./images/s_website.svg",
      des: "implementation company with a team of certified professionals offering the best sales force certified professionals",
    },
    {
      id: "3",
      pathname: "/mobile-development",
      title: "Mobile App Development",
      alt: "Mobile App Development Icon",
      icon: "./images/s_mobile.svg",
      des: "with a team of certified professionals offering the best salesforce certified professionals offering the best implementation company",
    },
    {
      id: "4",
      pathname: "/digital-marketing",
      title: "Digital Marketing",
      alt: "Digital Marketing Icon",
      icon: "./images/s_digital_marketing.svg",
      des: "implementation company with a team of certified professionals offering the best sales force certified professionals",
    },
    {
      id: "5",
      pathname: "/crm-system",
      title: "CRM System",
      alt: "CRM System Icon",
      icon: "./images/s_crm.svg",
      des: "with a team of certified professionals offering the best salesforce certified professionals offering the best implementation company",
    },
    {
      id: "6",
      pathname: "/ui-ux-design",
      title: "UI/UX Design",
      alt: "UI/UX Design Icon",
      icon: "./images/s_uiux.svg",
      des: "with a team of certified professionals offering the best salesforce certified professionals offering the best implementation company",
    },
    {
      id: "7",
      pathname: "/software-security",
      title: "Software Security",
      alt: "Software Security Icon",
      icon: "./images/s_software_security.svg",
      des: "with a team of certified professionals offering the best salesforce certified professionals offering the best implementation company",
    },
    {
      id: "8",
      pathname: "/web-hosting",
      title: "Web Hosting",
      alt: "Web Hosting Icon",
      icon: "./images/s_web_hosting.svg",
      des: "with a team of certified professionals offering the best salesforce certified professionals offering the best implementation company",
    },
  ];

  return (
    <>
      <div className="service_header">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <h1>Our Services</h1>
            </div>
            <div className="col-auto graphIcon">
              <img src="./images/graphic_1.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <section className="all_services">
        <div className="container">
          <div className="ser_list">
            {data?.map((ele,i) => {
              return (
                <div className="s_items" key={i}>
                  <h2>{ele?.title}</h2>
                  <p>{ele?.des}</p>
                  <figure>
                    <img src={ele.icon} alt={ele.alt} />
                  </figure>
                  <Link to={ele.pathname} className="stretched-link"></Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <StartProjectCard />
    </>
  );
}

export default Services;
