import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { BsChevronDown, BsChevronLeft } from "react-icons/bs";
import { technology } from "../Pages/Technologies";

function Header() {
  const [menuActive, setMenuActive] = useState(false);
  const [subMenuActive, setSubMenuActive] = useState(false);
  const [currentMenuTitle, setCurrentMenuTitle] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [activeSubMenuId, setActiveSubMenuId] = useState(1);
  const [activeSubMenuTech, setActiveSubMenuTech] = useState({
    id: 1,
    techArr: [
      { src: "/images/menu-icon/flutter.svg", name: "Flutter" },
      { src: "/images/menu-icon/react.svg", name: "React Native" },
      { src: "/images/menu-icon/iconic.svg", name: "Ionic" },
      { src: "/images/menu-icon/java.svg", name: "Java" },
      { src: "/images/menu-icon/swift.svg", name: "Swift" },
      { src: "/images/menu-icon/xman.svg", name: "Xamarin" },
      { src: "/images/menu-icon/cordova.svg", name: "Apache Cordova" },
      { src: "/images/menu-icon/native.svg", name: "Native Script" },
      { src: "/images/menu-icon/jquery.svg", name: "jQuery Mobile" },
      { src: "/images/menu-icon/framework.svg", name: "Framework7" },
      {
        src: "/images/menu-icon/kotlin.svg",
        name: "Kotlin Multiplatform Mobile",
      },
    ], // Using the existing mobile array directly
    heading: "Mobile App",
    desc: "Create cutting-edge mobile applications that deliver seamless user experiences across platforms.",
  });
  const subMenuRef = useRef(null);
  const categories = [
    {
      id: 1,
      key: "Mobile App",
      technologies: [
        "Flutter",
        "React Native",
        "Ionic",
        "Java",
        "Swift",
        "Kotlin",
      ],
    },
    {
      id: 2,

      key: "Web App",
      technologies: [
        "React",
        "Angular",
        "Vue.js",
        "Node.js",
        "Django",
        "Ruby on Rails",
      ],
    },
    {
      id: 3,

      key: "Ecommerce",
      technologies: [
        "Shopify",
        "Magento",
        "WooCommerce",
        "BigCommerce",
        "OpenCart",
      ],
    },
    {
      id: 4,

      key: "UI/UX",
      technologies: ["Figma", "Adobe XD", "Sketch", "InVision", "Axure"],
    },
    {
      id: 5,

      key: "Game",
      technologies: [
        "Unity",
        "Unreal Engine",
        "Godot",
        "Cocos2d",
        "GameMaker Studio",
      ],
    },
    {
      id: 6,
      key: "Devops",
      technologies: ["Docker", "Kubernetes", "Jenkins", "Ansible", "Terraform"],
    },
    {
      id: 7,
      key: "Cloud",
      technologies: [
        "AWS",
        "Azure",
        "Google Cloud Platform",
        "IBM Cloud",
        "Oracle Cloud",
      ],
    },
  ];

  // Example of how to access the data
  console.log(technology, activeSubMenuTech, activeSubMenuId, "derftghj");

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const showSubMenu = (title) => {
    setCurrentMenuTitle(title);
    setSubMenuActive(true);
    if (subMenuRef.current) {
      subMenuRef.current.style.animation = "slideLeft 0.5s ease forwards";
    }
  };

  const hideSubMenu = () => {
    if (subMenuRef.current) {
      subMenuRef.current.style.animation = "slideRight 0.5s ease forwards";
    }
    setTimeout(() => {
      setSubMenuActive(false);
      setCurrentMenuTitle("");
      setActiveSubMenu(null);
    }, 300);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991 && menuActive) {
        toggleMenu();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [menuActive]);

  const handleSubMenuClick = (title) => {
    setActiveSubMenu(title);
    setTimeout(() => {
      showSubMenu(title);
    }, 200);
  };

  return (
    <header className="header">
      <div className="container">
        <div className=" v-center header-container align-content-center">
          <div className="header-item item-left">
            <div className="logo">
              <Link to="/">
                <img src="/images/logo.svg" alt="logo" />
              </Link>
            </div>
          </div>
          <div className="header-item item-center">
            <div
              className={`menu-overlay ${menuActive ? "active" : ""}`}
              onClick={toggleMenu}
            ></div>
            <nav className={`menu ${menuActive ? "active" : ""}`}>
              <div
                className={`mobile-menu-head ${activeSubMenu ? "active" : ""}`}
              >
                <div className="go-back" onClick={hideSubMenu}>
                  <BsChevronLeft />
                </div>
                <div className="current-menu-title">{currentMenuTitle}</div>
                <div className="mobile-menu-close" onClick={toggleMenu}>
                  &times;
                </div>
              </div>
              <ul className="menu-main">
                <li className="menu-item-has-children">
                  <Link
                    to={"/services"}
                    onClick={() => handleSubMenuClick("Services")}
                    onMouseOver={() => handleSubMenuClick("Services")}
                  >
                    Services <BsChevronDown />
                  </Link>
                  {activeSubMenu === "Services" && (
                    <div
                      className={`sub-menu mega-menu mega-menu-column-4 ${
                        subMenuActive ? "active" : ""
                      }`}
                      ref={subMenuRef}
                    >
                      <div className="container d-flex align-items-start">
                        <ul className="serMenu">
                          <li>
                            <Link to="/software-development">
                              Software Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/website-development">
                              Website Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/mobile-development">
                              Mobile App Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/digital-marketing">
                              Digital Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="/ui-ux-design">UI/UX Design</Link>
                          </li>
                          <li>
                            <Link to="/software-security">
                              Software Security
                            </Link>
                          </li>
                          <li>
                            <Link to="/web-hosting">Web Hosting</Link>
                          </li>
                          <li>
                            <Link to="/crm-system">CRM system</Link>
                          </li>
                        </ul>
                        <div className="d-none d-lg-block">
                          <img
                            src="/images/star_graphic.svg"
                            alt="star image"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li className="menu-item-has-children">
                  <Link
                    to={"/technology"}
                    onClick={() => handleSubMenuClick("Technologies")}
                    // onMouseOver={() => handleSubMenuClick("Technologies")}
                  >
                    Technologies <BsChevronDown />
                  </Link>
                  {activeSubMenu === "Technologies" && (
                    <div
                      className={`sub-menu mega-menu mega-menu-column-4 ${
                        subMenuActive ? "active" : ""
                      }`}
                      ref={subMenuRef}
                    >
                      <div className="container d-flex justify-content-between ser_dropmenu">
                        <div className="list-item mainCat">
                          <ul>
                            {technology.map((item, i) => (
                              <li
                                onClick={() => {
                                  setActiveSubMenuId(item.id);
                                  setActiveSubMenuTech(item);
                                }}
                                onMouseOver={() => {
                                  setActiveSubMenuId(item.id);
                                  setActiveSubMenuTech(item);
                                }}
                              >
                                <Link
                                  to="/technology"
                                  className={
                                    activeSubMenuId === item.id && `active`
                                  }
                                  // className="active"
                                  onClick={() => {
                                    console.log(item, "item");
                                    setActiveSubMenuId(item.id);
                                    setActiveSubMenuTech(item.techArr);
                                  }}
                                >
                                  {item.heading}
                                  {/* <ScrollLink to="1">{item.heading}</ScrollLink> */}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="list-item list-item_2 ">
                          {activeSubMenuTech.techArr.length > 7 ? (
                            <>
                              <ul>
                                {activeSubMenuTech.techArr
                                  .slice(0, 7)
                                  .map((item, i) => (
                                    <li>
                                      <Link to="">
                                        <img src={item.src} alt="flutter" />{" "}
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                              <ul>
                                {activeSubMenuTech.techArr
                                  .slice(7)
                                  .map((item, i) => (
                                    <li>
                                      <Link to="">
                                        <img src={item.src} alt="flutter" />{" "}
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </>
                          ) : (
                            <ul>
                              {activeSubMenuTech.techArr.map((item, i) => (
                                <li>
                                  <Link to="">
                                    <img src={item.src} alt="flutter" />{" "}
                                    {item.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li>
                  <Link to="/career">Careers</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/our-work">Portfolio</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header-item item-right">
            <ul className="hireTalent d-none d-lg-block">
              {/* <li>
                <Link to="">Hire a Talent</Link>
              </li> */}
              <li>
                <Link to="/start-project">Get Quotes!</Link>
                {/* <a href="mailto:enquiry@pyndertech.com">Start a Project</a> */}
              </li>
            </ul>
            <div className="mobile-menu-trigger" onClick={toggleMenu}>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
