import React from "react";
import { Link } from "react-router-dom";

export default function Error() {
  return (
    <div
      className="container error-page"
    //   style={{
    //     textAlign: "center",
    //     padding: "50px",
    //     color: "#2737c3",
    //     height: "60vh",
    //   }}
    >
      <h1 className="fw-bold">404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link to="/">Go Back to Home</Link>
    </div>
  );
}
