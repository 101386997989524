import React from "react"
import AllServicesHeader from "../ServiceHeader/AllServicesHeader";
import FaqCard from "../AllCards/FaqCard";
import StartProjectCard from "../AllCards/StartProjectCard";
import { BsArrowRightShort } from "react-icons/bs";
function SoftwareSecurity(){
    return(
        <>
        <AllServicesHeader/>
        <section className="space_top">
            <div className="container">
                <div className="sft_img">
                    <div className="row ">
                       <div className="col-lg-5 soft_img d-none d-lg-block my-auto "><img src="./images/software-security.svg" alt="software security" /></div>
                       <div className="col-lg-7">
                        <div className="soft_img-2"><img src="./images/software-security-2.svg" alt="software security" /></div>
                        <h2 className="title_1">Software Security</h2>
                        <div className="para_txt">
                            <p>Pyndertech's expertise in software security, highlighting their proactive approach to threat assessment, secure development practices, compliance, and ongoing support. Customize the details to reflect Pyndertech's specific services, certifications, and client success stories in software security.</p>
                        </div>
                       </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className="create_website pb-lg-5">
                                <h2>Software Security</h2>
                                <p>Software development penetrates all the significant aspects of our life in this digital age. Individuals, startups, and big enterprises rely upon different software to carry out their daily tasks. There is no doubt that enterprise software development has the highest growth rate compared to every other tech industry. Besides, Software development is projected to outgrow in 2023. According to a report from Statista, Global spending on enterprise software development will touch 755bn USD in 2023. Yes! That’s Billions with ‘B.’</p>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className="create_website pb-lg-4">
                            <h2>Types of Software Security</h2>
                            <p>Among the various categories, there are generally four main type of software: They are:</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <div className="type_soft-box">
                            <h3>Authentication and Authorization </h3>
                            <p>Ensures that only authorized users have access to the software system or specific functionalities within the application. This includes implementing secure login mechanisms, multi-factor authentication (MFA), and role-based access control (RBAC). </p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="type_soft-box">
                            <h3>Data Encryption</h3>
                            <p>Encrypts sensitive data to protect it from unauthorized access during storage, transmission, and processing. Strong encryption algorithms and secure key management practices are essential for maintaining data confidentiality. </p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="type_soft-box">
                            <h3>Network security</h3>
                            <p>Secures communication channels and prevents unauthorized access to network resources. This includes implementing firewalls, intrusion detection systems (IDS), virtual private networks (VPNs), and secure socket layer (SSL) protocols. </p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="type_soft-box">
                            <h3>Cloud security</h3>
                            <p>Addresses unique security challenges associated with cloud computing environments, such as data breaches, data loss, and unauthorized access. Cloud security measures include data encryption, access control, and monitoring of cloud infrastructure and services. </p>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className="create_website pb-lg-4 app_cnt">
                            <h2>Authentication and Authorization</h2>
                            <p><b>Authentication</b> verifies the identity of users accessing the system. This typically involves usernames, passwords, and increasingly, multi-factor authentication (MFA) for added security.</p>
                            <p><b>Authorization</b> determines what actions authenticated users are allowed to perform within the system based on their roles and permissions. Role-based access control (RBAC) assigns permissions to roles, simplifying management of access rights.</p>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className="create_website pb-lg-4 app_cnt">
                        <h2>Data Encryption</h2>

<p><b>Data encryption </b>transforms data into a ciphertext format using encryption algorithms and keys, rendering it unreadable to unauthorized users. It protects sensitive information during storage (at rest), transmission (in transit), and processing (in use).
Common encryption standards include AES (Advanced Encryption Standard) for symmetric encryption and RSA (Rivest-Shamir-Adleman) for asymmetric encryption.</p>

                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className="create_website pb-lg-4 app_cnt">
                       <h2> Network Security</h2>

<p><b>Network security</b> safeguards the integrity, confidentiality, and availability of data and resources transmitted over networks.
Firewalls monitor and control incoming and outgoing network traffic based on predetermined security rules, preventing unauthorized access.
Intrusion Detection Systems (IDS) and Intrusion Prevention Systems (IPS) monitor network traffic for suspicious activity or known attack patterns and take action to block or mitigate threats.</p>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-12">
                        <div className="create_website pb-lg-4 app_cnt">
                            <h2>Why is software security important?</h2>
                            <p>Without a plan in place, software security can severely damage a company. As discussed, software security starts with the developers, making sure that the software is prepared for attacks or anything that tries to bring it down. This process is out of the hands of the end-user but should be an important part in deciding which software pieces to rely on.</p>
                            <p>After choosing the right software, it’s time to implement the software security best practices discussed. To do this, organizations must turn to software security solutions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FaqCard/>
        <StartProjectCard/>
        </>
    )
}

export default SoftwareSecurity;