import React from "react";
import img1 from "../clientimages/img1.png";
import img2 from "../clientimages/img2.png";
import img3 from "../clientimages/img3.png";
import img4 from "../clientimages/img4.png";
import img5 from "../clientimages/img5.png";
import img6 from "../clientimages/img6.png";
import img7 from "../clientimages/img7.png";
import img8 from "../clientimages/img8.png";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
const client = [
  
  { id: 8, src: img1 },
  // src: "../clientimages/8.jpg" },
  { id: 9, src: img2 },
  // src: "../clientimages/5.jpg" },
  { id: 10, src: img3 },
  // src: "../clientimages/9.jpg" },
  { id: 11, src: img4 },
  // src: "../clientimages/6.jpg" },
  { id: 12, src: img5 },
  // src: "../clientimages/7.jpg" },
  { id: 13, src: img6 },
  // src: "../clientimages/client1.jpg" },
  { id: 14, src: img7 },
  // src: "../clientimages/client2.jpg" },
  { id: 15, src: img8 },
  // src: "../clientimages/client3.jpg" },
  // { id: 16,
  //   src: img9},
  // src: "../clientimages/client4.jpg" },
];

function OurClient(props) {
  return (
    <>
      <section className="our_client">
        <div className="container">
          <div className="team_title text-center mb-lg-5 mb-3">
            <h3>Our Client</h3>
            <p>Let our design thinking guide innovation at your workplace.</p>
          </div>
          <div className="cl_main">
            {client?.map((ele, i) => {
              return (
                <div className="cl_logo" key={i}>
                  <img src={ele?.src} alt="clients" />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default OurClient;
