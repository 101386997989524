import React from "react";
import StartProjectCard from "../AllCards/StartProjectCard";
import { TbTargetArrow } from "react-icons/tb";
import { GiArtificialIntelligence } from "react-icons/gi";
import { TbCloudComputing } from "react-icons/tb";
import { SiCyberdefenders } from "react-icons/si";
import { SiDigitalocean } from "react-icons/si";
import { SiJirasoftware } from "react-icons/si";
import { FaDatabase } from "react-icons/fa6";
import { AiFillOpenAI } from "react-icons/ai";
import { SiHiveBlockchain } from "react-icons/si";
import { Helmet } from "react-helmet-async";

function About() {
  return (
    <>
        <Helmet>
          <title>About - Pyndertech</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      <section className="caarer">
        <div className="container">
          <div className="careTitle">
            <img src="./images/pay_icon.svg" alt="pyndertech icon" />
            <h1>About </h1>
            <p>
              Welcome to Avigat Technologies Private Limited (Pyndertech), where
              innovation meets excellence. We are a forward-thinking technology
              and digital marketing company dedicated to transforming businesses
              through cutting-edge solutions and unparalleled expertise.
            </p>
            {/* <p>Website development involves creating, building, publishing, and maintaining a website for the world-wide-web (internet) or private network (intranet). Using many open-source available tools, we can develop a website with simple static content, or build complex web applications, offer services, and run commerce businesses.</p> */}

            <section>
              <div className="container">
                <div className="cnt_box">
                  <h2>
                    <span>
                      <i className="fa fa-comments-o" aria-hidden="true" />
                    </span>
                    &nbsp;<b>Who We Are</b>
                  </h2>
                  <p>
                    At Avigat Technologies, also known as Pyndertech, we are
                    passionate about leveraging technology to drive business
                    success. Our team of experienced professionals brings
                    together a wealth of knowledge in AI and machine learning,
                    cloud computing, cybersecurity, digital transformation,
                    software development, data analytics, IT consulting, and
                    blockchain solutions. We are committed to delivering
                    tailored, innovative solutions that meet the unique needs of
                    our clients.
                  </p>

                  <h2>
                    <span>
                      <TbTargetArrow />{" "}
                    </span>
                    &nbsp;<b>Our Mission</b>
                  </h2>
                  <p>
                    Our mission is to empower businesses with the tools and
                    strategies they need to thrive in the digital age. We aim to
                    be a trusted partner, helping our clients navigate the
                    complexities of technology and digital marketing to achieve
                    their goals.
                  </p>

                  <h2>
                    <span>
                      <GiArtificialIntelligence />
                    </span>
                    &nbsp;<b>AI and Machine Learning</b>
                  </h2>
                  <p>
                    Harnessing the power of AI and machine learning to deliver
                    intelligent automation, predictive analytics, and advanced
                    data processing solutions.
                  </p>
                  <h2>
                    <span>
                      <TbCloudComputing />
                    </span>
                    &nbsp;<b>Cloud Computing</b>
                  </h2>
                  <p>
                    Offering seamless cloud migration, infrastructure
                    management, and scalability solutions to enhance operational
                    efficiency and flexibility.
                  </p>
                  <h2>
                    <span>
                      <SiCyberdefenders />
                    </span>
                    &nbsp;<b>Cybersecurity</b>
                  </h2>
                  <p>
                    Implementing robust security measures to protect your data
                    and ensure compliance with industry standards.
                  </p>
                  <h2>
                    <span>
                      <SiDigitalocean />
                    </span>
                    &nbsp;<b>Digital Transformation</b>
                  </h2>
                  <p>
                    Guiding businesses through the digital transformation
                    journey with process automation, strategic consulting, and
                    technology implementation.
                  </p>
                  <h2>
                    <span>
                      <SiJirasoftware />
                    </span>
                    &nbsp;<b>Software Development</b>
                  </h2>
                  <p>
                    Creating custom software solutions, mobile applications, and
                    seamless software integrations to meet your specific
                    business needs.
                  </p>
                  <h2>
                    <span>
                      <FaDatabase />
                    </span>
                    &nbsp;<b>Data Analytics</b>
                  </h2>
                  <p>
                    Providing big data solutions, business intelligence, and
                    data visualization to help you make informed decisions.
                  </p>
                  <h2>
                    <span>
                      <AiFillOpenAI />
                    </span>
                    &nbsp; <b>IT Consulting</b>
                  </h2>
                  <p>
                    Delivering strategic IT planning, system integration, and IT
                    infrastructure optimization to drive business growth.
                  </p>
                  <h2>
                    <span>
                      <SiHiveBlockchain />
                    </span>
                    &nbsp;<b>Blockchain Solutions</b>
                  </h2>
                  <p>
                    Exploring the potential of blockchain technology with
                    distributed ledger systems, smart contracts, and
                    cryptocurrency integration.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <div>
        <section className="space_bottom">
          <div className="container">
            <div className="cnt_box">
              <h2>
                <b>Our Approach</b>
              </h2>
              <p>
                At Pyndertech, we believe in a client-centric approach. We work
                closely with our clients to understand their challenges and
                objectives, developing bespoke solutions that drive results. Our
                agile methodologies ensure that we can adapt quickly to changing
                needs and deliver projects on time and within budget.
              </p>
            </div>
          </div>
        </section>
      </div>
      <section className="space_bottom">
        <div className="container">
          <div className="cnt_box">
            <div className="create_website">
              <h2>
                <b>Our Values</b>
              </h2>
            </div>
            <h3>
              <b>Innovation</b>
            </h3>
            <p>
              We are committed to staying at the forefront of technology and
              continuously exploring new ways to innovate.
            </p>
            <h3>
              <b>Integrity</b>
            </h3>
            <p>
              We uphold the highest standards of integrity and transparency in
              all our interactions and business practices.
            </p>
            <h3>
              <b>Collaboration</b>
            </h3>
            <p>
              We believe in the power of teamwork and foster a collaborative
              environment where diverse ideas and perspectives thrive.
            </p>
            <h3>
              <b>Excellence</b>
            </h3>
            <p>
              We strive for excellence in everything we do, from the solutions
              we deliver to the customer service we provide.
            </p>
          </div>
        </div>
      </section>
      <section className="space_bottom">
        <div className="container">
          <div className="cnt_box leader_cnt">
            <img src="./images/white_graphic.svg" alt="background banner F" />
            <h2> Why Choose Pyndertech?</h2>
            <p>
              Choosing Pyndertech means partnering with a team of dedicated
              professionals who are passionate about technology and committed to
              your success. Our holistic approach, industry expertise, and
              unwavering commitment to quality set us apart as a leader in the
              tech and digital marketing space. Thank you for considering Avigat
              Technologies Private Limited (Pyndertech) as your trusted partner.
              We look forward to working with you to achieve your business goals
              and drive innovation.
            </p>
          </div>
        </div>
      </section>
      {/* <section className="space_bottom">
        <div className="container">
          <div className="create_website">
            <h2>Leadership</h2>
          </div>
          <div className="img_galley">
            <div>
              <figure>
                <img src="./images/team_1.png" className="img-fluid" alt="" />
              </figure>
              <figcaption>Danny martin</figcaption>
            </div>
            <div>
              <figure>
                <img src="./images/team_2.png" className="img-fluid" alt="" />
              </figure>
              <figcaption>Danny martin</figcaption>
            </div>
            <div>
              <figure>
                <img src="./images/team_3.png" className="img-fluid" alt="" />
              </figure>
              <figcaption>Danny martin</figcaption>
            </div>
            <div>
              <figure>
                <img src="./images/team_4.png" className="img-fluid" alt="" />
              </figure>
              <figcaption>Danny martin</figcaption>
            </div>
            <div>
              <figure>
                <img src="./images/team_5.png" className="img-fluid" alt="" />
              </figure>
              <figcaption>Danny martin</figcaption>
            </div>
          </div>
        </div>
      </section>
      <section className="space_bottom">
        <div className="container">
          <div className="create_website">
            <h2>Team Members</h2>
          </div>
          <div className="img_galley">
            <div>
              <figure>
                <img src="./images/team_1.png" className="img-fluid" alt="" />
              </figure>
              <figcaption>Danny martin</figcaption>
            </div>
            <div>
              <figure>
                <img src="./images/team_2.png" className="img-fluid" alt="" />
              </figure>
              <figcaption>Danny martin</figcaption>
            </div>
            <div>
              <figure>
                <img src="./images/team_3.png" className="img-fluid" alt="" />
              </figure>
              <figcaption>Danny martin</figcaption>
            </div>
            <div>
              <figure>
                <img src="./images/team_4.png" className="img-fluid" alt="" />
              </figure>
              <figcaption>Danny martin</figcaption>
            </div>
            <div>
              <figure>
                <img src="./images/team_5.png" className="img-fluid" alt="" />
              </figure>
              <figcaption>Danny martin</figcaption>
            </div>
          </div>
        </div>
      </section> */}
      <StartProjectCard />
    </>
  );
}

export default About;
